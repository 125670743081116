import React from "react";

import "./App.scss";

// React-Router
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import Background from 'components/background';
import About from 'components/about';
import Landing from 'components/landing';
import Header from 'components/header';
import Footer from 'components/footer';

import { useLayout } from 'hooks';
import LayoutContext from 'context/layoutContext';

import classnames from 'classnames';

const App = () => {
  const layout = useLayout();
  
  const appCss = classnames("app", layout);

  return (
      <LayoutContext.Provider value={layout}>
        <div className={appCss}>
            <Background />
            <div className="content-container">
              <Router>
                <Header />

                <Switch>
                  <Route path="/about">
                    <About />
                  </Route>

                  <Route path="/">
                    <Landing />
                  </Route>
                </Switch>
                
                <Footer />
              </Router>
            </div>
        </div>
      </LayoutContext.Provider>
  )
}

export default App;


// class App extends Component {
//   state = { storageValue: 0, web3: null, accounts: null, contract: null };

//   componentDidMount = async () => {
//     try {
//       // Get network provider and web3 instance.
//       const web3 = await getWeb3();

//       // Use web3 to get the user's accounts.
//       const accounts = await web3.eth.getAccounts();


//       // Get the contract instance.
//       const networkId = await web3.eth.net.getId();
//       const deployedNetwork = SimpleStorageContract.networks[networkId];
//       const instance = new web3.eth.Contract(
//         SimpleStorageContract.abi,
//         deployedNetwork && deployedNetwork.address,
//       );

//       // Set web3, accounts, and contract to the state, and then proceed with an
//       // example of interacting with the contract's methods.
//       this.setState({ web3, accounts, contract: instance }, this.runExample);
//     } catch (error) {
//       // Catch any errors for any of the above operations.
//       alert(
//         `Failed to load web3, accounts, or contract. Check console for details.`,
//       );
//       console.error(error);
//     }
//   };

//   runExample = async () => {
//     const { accounts, contract } = this.state;
//     debugger;
//     // Stores a given value, 5 by default.
//     await contract.methods.set(5).send({ from: accounts[0] });

//     // Get the value from the contract to prove it worked.
//     const response = await contract.methods.get().call();

//     // Update state with the result.
//     this.setState({ storageValue: response });
//   };

//   render() {
//     if (!this.state.web3) {
//       return <div>Loading Web3, accounts, and contract...</div>;
//     }
//     return (
//       <div className="App">
//         <h1>Good to Go!</h1>
//         <p>Your Truffle Box is installed and ready.</p>
//         <h2>Smart Contract Example</h2>
//         <p>
//           If your contracts compiled and migrated successfully, below will show
//           a stored value of 5 (by default).
//         </p>
//         <p>
//           Try changing the value stored on <strong>line 40</strong> of App.js.
//         </p>
//         <div>The stored value is: {this.state.storageValue}</div>
//       </div>
//     );
//   }
// }


  // useEffect(() => {
  //   const runExample = async () => {

  //     return;
  //     if (!contract) return;

  //     // Stores a given value, 5 by default.
  //     await contract.methods.set(5).send({ from: accounts[0] });
  
  //     // Get the value from the contract to prove it worked.
  //     const response = await contract.methods.get().call();
  
  //     // Update state with the result.
  //     setStorageValue(response);
  //   };

  //   runExample();
  // }, [ contract ]);